/**
 * @flow
 */
import React from "react"
import styled from "styled-components"
import { Field, Form as FormikForm, Formik } from "formik"
import * as Yup from "yup"

import media from "../../../styles/media"
import { Button, EmailField } from "../../elements"

type Values = {
  email: string,
}

type Actions = {
  setSubmitting: Function,
}

type Props = {
  onSubmit: (values: Values, actions: Actions) => void | Promise<mixed>,
  initialValues?: Values,
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
})

const Container = styled.div`
  .inline-form {
    display: flex;
    flex-direction: column;
  }
  ${media.tablet`
    .inline-form {
      flex-direction: row;
      justify-content: space-between;
    }
  `}
`

const Form = ({
  onSubmit,
  initialValues = {
    email: "",
  },
}: Props) => {
  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <FormikForm noValidate>
            <div className="inline-form">
              <Field
                autoComplete="email"
                autoFocus
                component={EmailField}
                id="email"
                name="email"
                placeholder="Email Address"
                required
              />
              <Button
                type="submit"
                label="Send Instructions"
                className="mouse-link"
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </div>
          </FormikForm>
        )}
      </Formik>
    </Container>
  )
}

export default Form
