import React from "react"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import ForgotPasswordPage from "../components/Auth/ForgotPasswordPage"

const ForgotPassword = () => {
  const menuItems = [
    // { name: "Sign In", path: "/sign-in" },
    { name: "Leaderboard", path: "/leaderboard" },
    // { name: "Profiles", path: "/profiles" },
  ]
  return (
    <>
      <SEO title="Forgot Password" />
      <Navbar isGHP back items={menuItems} topOffset={0} bottomOffset={125} />
      <ForgotPasswordPage />
      <Footer back />
    </>
  )
}

export default ForgotPassword
